export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99')
];

export const server_loads = [];

export const dictionary = {
		"/": [19],
		"/(app)/(login)/auth": [83,[2,18]],
		"/(app)/(other)/change-organization": [90,[2]],
		"/(app)/(login)/cred-login": [84,[2,18]],
		"/(app)/(authorised)/cvo-requests": [21,[2,3,5]],
		"/(app)/(authorised)/cvo-requests/[path]": [22,[2,3,5]],
		"/(app)/(authorised)/discover/license-verification": [23,[2,3]],
		"/(app)/(authorised)/discover/provider-profile": [24,[2,3]],
		"/(app)/(authorised)/discover/provider-profile/[id]": [25,[2,3]],
		"/(app)/(authorised)/discover/search": [26,[2,3]],
		"/(app)/(login)/forgot-password": [85,[2,18]],
		"/(app)/(authorised)/groups": [31,[2,3]],
		"/(app)/(authorised)/group/[groupId=uuid]": [27,[2,3]],
		"/(app)/(authorised)/group/[groupId=uuid]/detail/overview": [28,[2,3,6,7]],
		"/(app)/(authorised)/group/[groupId=uuid]/detail/overview/[tab]": [29,[2,3,6,7]],
		"/(app)/(authorised)/group/[groupId=uuid]/detail/payer/[id]": [30,[2,3,6]],
		"/(app)/(login)/login": [86,[2,18]],
		"/(other)/maintenance": [96],
		"/(app)/(authorised)/my-network": [32,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/cvo-requests": [33,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/cvo-requests/assigned": [34,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/cvo-requests/cancellation-request": [35,[2,3,8]],
		"/(app)/(authorised)/my-network/issues": [42,[2,3,8]],
		"/(app)/(authorised)/my-network/issues/failed-verifications": [43,[2,3,8]],
		"/(app)/(authorised)/my-network/issues/failed-verifications/[tab=failedVerificationTab]": [44,[2,3,8]],
		"/(app)/(authorised)/my-network/issues/monitoring-alerts": [45,[2,3,8]],
		"/(app)/(authorised)/my-network/issues/npdb": [46,[2,3,8]],
		"/(app)/(authorised)/my-network/issues/sanctions": [47,[2,3,8]],
		"/(app)/(authorised)/my-network/monitoring": [48,[2,3,8]],
		"/(app)/(authorised)/my-network/monitoring/dashboard": [49,[2,3,8]],
		"/(app)/(authorised)/my-network/monitoring/providers": [50,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review": [51,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/board": [52,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/needs-review/caqh": [36,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/needs-review/caqh/import": [37,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/needs-review/caqh/merge": [38,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/certificate": [53,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/needs-review/cvo": [39,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/dea": [54,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/license": [55,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/medicaid": [56,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/npdb": [57,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/npi": [58,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/profile": [59,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/profile/[tab=profileImportListTab]": [60,[2,3,8]],
		"/(app)/(authorised)/my-network/needs-review/sanctions": [61,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/needsReview/[...any]": [40,[2,3,8,9]],
		"/(app)/(authorised)/my-network/provider-license": [62,[2,3,8]],
		"/(app)/(authorised)/my-network/(backward-routes)/providerLicense/[...any]": [41,[2,3,8,10]],
		"/(app)/(authorised)/my-network/providers": [63,[2,3,8]],
		"/(app)/(login)/new-password": [87,[2,18]],
		"/(other)/not-support": [97],
		"/(app)/(other)/org-picker": [91,[2]],
		"/(app)/(other)/print/[providerId=uuid]/[entity]/[id]": [92,[2]],
		"/(app)/(authorised)/(backward-routes)/provider-detail/[...any]": [20,[2,3,4]],
		"/(app)/(authorised)/provider/[provider=uuid]": [64,[2,3,11]],
		"/(app)/(authorised)/provider/[provider=uuid]/ama-merge": [66,[2,12]],
		"/(app)/(authorised)/provider/[provider=uuid]/ama-merge/[recordId]": [67,[2,12]],
		"/(app)/(authorised)/provider/[provider=uuid]/ama-merge/[recordId]/add": [68,[2,12]],
		"/(app)/(authorised)/provider/[provider=uuid]/ama-merge/[recordId]/review": [69,[2,12]],
		"/(app)/(authorised)/provider/[provider=uuid]/caqh-merge": [70,[2,13]],
		"/(app)/(authorised)/provider/[provider=uuid]/caqh-merge/[recordId]": [71,[2,13]],
		"/(app)/(authorised)/provider/[provider=uuid]/caqh-merge/[recordId]/add": [72,[2,13]],
		"/(app)/(authorised)/provider/[provider=uuid]/caqh-merge/[recordId]/resolve": [73,[2,13]],
		"/(app)/(authorised)/provider/[provider=uuid]/caqh-merge/[recordId]/review": [74,[2,13]],
		"/(app)/(authorised)/provider/[provider=uuid]/enrollment": [75,[2,3,11,14]],
		"/(app)/(authorised)/provider/[provider=uuid]/enrollment/[tab]": [76,[2,3,11,14]],
		"/(app)/(authorised)/provider/[provider=uuid]/[...tab]": [65,[2,3,11]],
		"/(other)/report-template": [98],
		"/(other)/report-template/[template]": [99],
		"/(app)/(login)/reset-password": [88,[2,18]],
		"/(app)/(other)/review/alert/[id]": [94,[2]],
		"/(app)/(other)/review/[providerId=uuid]/[entity]/[id]": [93,[2]],
		"/(app)/(other)/select-providers/[entityId]": [95,[2]],
		"/(app)/(authorised)/settings": [77,[2,3,15]],
		"/(app)/(authorised)/settings/account": [78,[2,3,15,16]],
		"/(app)/(authorised)/settings/account/[tab]": [79,[2,3,15,16]],
		"/(app)/(authorised)/settings/audit-logs": [80,[2,3,15]],
		"/(app)/(authorised)/settings/coverage": [81,[2,3,15,17]],
		"/(app)/(authorised)/settings/coverage/[tab]": [82,[2,3,15,17]],
		"/(app)/(login)/temp-token-login": [89,[2,18]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';